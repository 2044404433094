import { createApp } from 'vue'
import App from './App.vue'
import Quasar from 'quasar/src/vue-plugin.js';import Loading from 'quasar/src/plugins/Loading.js';
import quasarUserOptions from './quasar-user-options'
import routes from "@/Routes/routes.js"
import { createPinia } from 'pinia';

const app = createApp(App);
app.config.globalProperties.$danUrl = "https://dan.com/buy-domain/";
app.config.globalProperties.$sedoUrl = "https://sedo.com/search/details/?domain=";
app.config.globalProperties.$afternicUrl = "https://www.afternic.com/domain/";

app.use(routes);
app.use(createPinia());
app.use(Quasar, {
    plugins: {
      Loading
    },
    config: {
      loading: { /* look at QuasarConfOptions from the API card */ }
    }
  }, quasarUserOptions);
app.mount('#app');

