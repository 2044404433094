import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/stores';

const routes = [
  {
    path: '/portfolio/:Did',
    name: 'Portfolio Item',
    component: () => import('@/components/PortfolioItem'),
  },
  {
    path: '/watched/:Did',
    name: 'Watched Item',
    component: () => import('@/components/WatchedItem'),
  },
  {
    path: '/extension/:Ext',
    name: 'Portfolio Extension',
    component: () => import('@/components/PortfolioExtension'),
  },
  {
    path: '/registrar/:Registrar',
    name: 'Portfolio Registrar',
    component: () => import('@/components/PortfolioRegistrar'),
  },
  {
    path: '/nameserver/:NS',
    name: 'Portfolio NS',
    component: () => import('@/components/PortfolioNS'),
  },
  // {
  //   path: '/portfolio/:Did/upgrade',
  //   name: 'Domain Upgrade',
  //   component: () => import('@/components/DomainUpgrade'),
  // },
  {
    path: '/portfolio/:Did/upgrade/:WordPack',
    name: 'Domain Upgrade',
    component: () => import('@/components/DomainUpgrade'),
  },
  {
    path: '/portfolio',
    name: 'Portfolio List',
    component: () => import('@/components/PortfolioList'),
  },
  {
    path: '/portfolioprices',
    name: 'Portfolio List Prices',
    component: () => import('@/components/PortfolioListWithPrices'),
  },
  {
    path: '/watchedprices',
    name: 'Watched List Prices',
    component: () => import('@/components/WatchedListWithPrices'),
  },
  {
    path: '/portfolio/leads',
    name: 'Portfolio Leads',
    component: () => import('@/components/PortfolioLeads'),
  },
  {
    path: '/api/keys',
    name: 'API Keys',
    component: () => import('@/components/ApiKeys'),
  },
  {
    path: '/api/usage',
    name: 'API Usage',
    component: () => import('@/components/ApiUsage'),
  },
  {
     path: '/dnwe',
     name: 'DNWE List',
     component: () => import('@/components/DNWEList'),
  },
  {
     path: '/dnweauction',
     name: 'DNWE Auction',
     component: () => import('@/components/DNWEAuction'),
  },
  {
    path: '/Watched',
    name: 'Watched List',
    component: () => import('@/components/WatchedList'),
  },
  {
    path: '/portfolio/listed/dan',
    name: 'Listed At Dan',
    component: () => import('@/components/ListedAtDan'),
  },
  {
    path: '/portfolio/listed/sedo',
    name: 'Listed At Sedo',
    component: () => import('@/components/ListedAtSedo'),
  },
  {
    path: '/portfolio/listed/afternic',
    name: 'Listed At Afternic',
    component: () => import('@/components/ListedAtAfternic'),
  },
  {
    path: '/portfolio/unlisted/dan',
    name: 'Unlisted At Dan',
    component: () => import('@/components/UnlistedAtDan'),
  },
  {
    path: '/portfolio/unlisted/sedo',
    name: 'Unlisted At Sedo',
    component: () => import('@/components/UnlistedAtSedo'),
  },
  {
    path: '/portfolio/unlisted/afternic',
    name: 'Unlisted At Afternic',
    component: () => import('@/components/UnlistedAtAfternic'),
  },
  {
    path: '/portfolio/expiring/30',
    name: 'Expiring 30 Days',
    component: () => import('@/components/Expiry30Days'),
  },
  {
    path: '/portfolio/expiring/60',
    name: 'Expiring 60 Days',
    component: () => import('@/components/Expiry60Days'),
  },
  {
    path: '/portfolio/expired/dan',
    name: 'Expired At Dan',
    component: () => import('@/components/ExpiredAtDan'),
  },
  {
    path: '/portfolio/expired/sedo',
    name: 'Expired At Sedo',
    component: () => import('@/components/ExpiredAtSedo'),
  },
  {
    path: '/portfolio/expired/afternic',
    name: 'Expired At Afternic',
    component: () => import('@/components/ExpiredAtAfternic'),
  },  
  {
    path: '/portfolio/whois/supported',
    name: 'Whois Supported Domains',
    component: () => import('@/components/WhoisSupported'),
  },
  {
    path: '/portfolio/whois/notsupported',
    name: 'Whois Not Supported Domains',
    component: () => import('@/components/WhoisNotSupported'),
  },
  {
    path: '/terms',
    name: 'Site Terms',
    component: () => import('@/components/SiteTerms'),
  },
  {
    path: '/contact',
    name: 'Contact Us',
    component: () => import('@/components/ContactUs'),
  },
  {
    path: '/portfolio/add',
    name: 'Portfolio Add',
    component: () => import('@/components/PortfolioAdd'),
  }, 
  {
    path: '/ext/search',
    name: 'Ext Search',
    component: () => import('@/components/ExtSearch'),
  }, 
  {
    path: '/Watched/add',
    name: 'Watched Add',
    component: () => import('@/components/WatchedAdd'),
  },  {
    path: '/portfolio/queued',
    name: 'Portfolio Queued',
    component: () => import('@/components/PortfolioQueued'),
  }, 
  {
    path: '/portfolio/awaitingconfirmation',
    name: 'Awaiting Confirmation',
    component: () => import('@/components/PortfolioConfirmation'),
  },{
    path: '/',
    name: 'DefaultHome',
    component: () => import('@/components/DefaultHome'),
  }, 
  {
    path: '/Latest',
    name: 'WhatsNew',
    component: () => import('@/components/WhatsNew'),
  }, 
  {
    path: '/PortfolioIntegrations',
    name: 'Portfolio Integrations',
    component: () => import('@/components/PortfolioIntegrations'),
  }, 
  {
    path: '/betabugs',
    name: 'Beta Bugs',
    component: () => import('@/components/BetaBugs'),
  }, 
  {
    path: '/portfolio/reminders',
    name: 'Portfolio Reminders',
    component: () => import('@/components/PortfolioReminders'),
  }, 
  {
    path: '/portfolio/tags',
    name: 'Portfolio Tags',
    component: () => import('@/components/PortfolioTags'),
  }, 
  {
    path: '/portfolio/reports',
    name: 'Portfolio Reports',
    component: () => import('@/components/PortfolioReports'),
  }, 
  // {
  //   path: '/Profile',
  //   name: 'Profile',
  //   component: () => import('@/components/Profile'),
  // }, 
  {
    path: '/zone/search',
    name: 'ZoneSearch',
    component: () => import('@/components/ZoneSearch'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/LoginView'),
  },
]

  const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(async (to) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const auth = useAuthStore();

  if (authRequired && !auth.user && to.fullPath != '/contact' && to.fullPath != '/terms') {
      auth.returnUrl = to.fullPath;
      return '/login';
  }
});

export default router 