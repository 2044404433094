<template>
  <q-layout view="lHh Lpr lFf">
    <q-header elevated>
      <q-toolbar class="menu">
        <q-btn
          flat
          dense
          round
          @click="leftDrawerOpen = !leftDrawerOpen"
          aria-label="Menu"
          icon="menu"
          class="menu-color"
        />

        <q-toolbar-title>
          <q-item clickable tag="a" href="/">   
            <img src="/logo.png" style="height:40px" alt="DNBASE.com">
          </q-item>
          
        </q-toolbar-title>

        <div class="menu-button">
          <q-item clickable tag="a" href="/portfolio">   
            <q-btn  color="secondary" icon-right="widgets" label="Portfolio">
            </q-btn>
          </q-item>
        </div>
      </q-toolbar>
    </q-header>

    <q-drawer
      v-model="leftDrawerOpen"
      show-if-above
      bordered
      class="bg-white-2"
    >
      <q-list>

        <q-item>          
          <q-item-section>
            <q-item-label>Portfolio</q-item-label>
            <q-item clickable tag="a" href="/">   
              <q-item-label class="text-caption" caption>
                <q-icon name="home" class="text-primary"></q-icon>
                Home
              </q-item-label>
            </q-item>
            <q-item clickable tag="a" href="/portfolio">   
              <q-item-label class="text-caption" caption>
                <q-icon name="widgets" class="text-primary"></q-icon>
                Portfolio
              </q-item-label>
            </q-item>
            <q-item clickable tag="a" href="/portfolio/reminders">   
              <q-item-label class="text-caption" caption>
                <q-icon name="alarm" class="text-primary"></q-icon>
                Reminders
              </q-item-label>
            </q-item>          
            <q-item clickable tag="a" href="/portfolio/leads">   
              <q-item-label class="text-caption" caption>
                <q-icon name="people" class="text-primary"></q-icon>
                Leads
              </q-item-label>
            </q-item>          
            <!--<q-item clickable tag="a" href="/portfolio/reports">   
              <q-item-label class="text-caption" caption>
                <q-icon name="query_stats" class="text-primary"></q-icon>
                Reports
              </q-item-label>
            </q-item>           -->
            <!-- <q-item clickable tag="a" href="/portfolio/tags">   
              <q-item-label class="text-caption" caption>
                <q-icon name="new_label" class="text-primary"></q-icon>
                Tags
              </q-item-label>
            </q-item>           -->
            <q-item clickable tag="a" href="/watched">   
              <q-item-label class="text-caption" caption>
                <q-icon name="next_plan" class="text-primary"></q-icon>
                Watched Names
              </q-item-label>
            </q-item>          
          </q-item-section>
        </q-item>
        <q-item>          
          <q-item-section>
            <q-item-label>Tools</q-item-label>
            <q-item clickable tag="a" href="/ext/search">   
            <q-item-label class="text-caption" caption>
              <q-icon name="manage_search" class="text-primary"></q-icon>
              Extension Search
            </q-item-label>
          </q-item>
          <q-item clickable tag="a" href="#">   
            <q-item-label class="text-caption" caption>
              <q-icon name="youtube_searched_for" class="text-primary"></q-icon>
              Zone Search (Coming Soon)
            </q-item-label>
          </q-item>
          </q-item-section>
        </q-item>
        <q-item>
          <q-item-section>
            <q-item-label>API</q-item-label>
            <q-item  clickable tag="a" target="_blank" href="https://api.dnbase.com">   
            <q-item-label class="text-caption" caption>
              <q-icon name="code" class="text-primary"></q-icon>
              Guide
            </q-item-label>
          </q-item>
          <q-item clickable tag="a" href="/api/keys">   
            <q-item-label class="text-caption" caption>
              <q-icon name="key" class="text-primary"></q-icon>
              Keys
            </q-item-label>
          </q-item>
          <q-item clickable tag="a" href="/api/usage">   
            <q-item-label class="text-caption" caption>
              <q-icon name="query_stats" class="text-primary"></q-icon>
              Usage
            </q-item-label>
          </q-item>
          </q-item-section>
        </q-item>
        <q-item>
          <q-item-section>
            <q-item-label>Admin</q-item-label>
            <q-item clickable tag="a" href="/latest">   
            <q-item-label class="text-caption" caption>
              <q-icon name="notes" class="text-primary"></q-icon>
              What's New?
            </q-item-label>
          </q-item>
          <q-item clickable tag="a" href="/betabugs">   
            <q-item-label class="text-caption" caption>
              <q-icon name="medical_information" class="red"></q-icon>
              Beta Bugs
            </q-item-label>
          </q-item>
          <q-item clickable tag="a" href="/portfoliointegrations">   
            <q-item-label class="text-caption" caption>
              <q-icon name="near_me" class="text-primary"></q-icon>
              Integrations
            </q-item-label>
          </q-item>
          <!-- <q-item clickable tag="a" href="/profile">   
            <q-item-label class="text-caption" caption>
              <q-icon name="person" class="text-primary"></q-icon>
              Profile
            </q-item-label>
          </q-item> -->
          <q-item clickable @click="authStore.logout();">   
            <q-item-label class="text-caption" caption>
              <q-icon name="logout" class="text-primary"></q-icon>
              Logout
            </q-item-label>
          </q-item>
          </q-item-section>
        </q-item>
      </q-list>
    </q-drawer>
    <q-page-container>
      <nav v-show="authStore.user" class="navbar navbar-expand navbar-dark bg-dark">
            <div class="navbar-nav">
                <RouterLink to="/" class="nav-item nav-link">Home</RouterLink>
                <a @click="authStore.logout()" class="nav-item nav-link">Logout</a>
            </div>
        </nav>
      <router-view></router-view>
    </q-page-container>
    <q-footer bordered class="bg-white text-primary">
      <q-list>

      <q-item style="justify-content:end">                 
          <q-item clickable tag="a" href="/terms">   
            <q-item-label class="text-caption" caption>
              Site Terms
            </q-item-label>
          </q-item>
          <q-item clickable tag="a" href="/contact">   
            <q-item-label class="text-caption" caption>
              Contact Us
            </q-item-label>
          </q-item>
          <q-item>   
            <q-item-label class="text-caption" caption>
              Version 0.1.34
            </q-item-label>
          </q-item>
      </q-item>
      </q-list>

      </q-footer>

  </q-layout>
</template>

<style>
.red{
    color:#aa0000;
  } 
  
.q-btn span {
  padding:0px; 
  /* color:#ffffff;  */
}

#deleteButton.q-btn span {
  padding:0px; 
  color:#ffffff; 
}

#rerunButton.q-btn span {
  padding:0px; 
  color:#ffffff; 
}

#exportButton span {
  color:#000;
}

.delete {
  bottom:10px;
}
.menu .q-btn .q-icon, .q-btn .q-spinner {
  color:#ffffff!important;
}
.menu-button span {
  color:#ffffff!important;
  font-size: 13px!important;
  font-weight: 350;
  line-height: 1.5em;
  word-break: normal;
}
  .q-list{
      font-size: 13px;
      font-weight: 850;
      line-height: 2em;
      color: #2F4F4F;
      word-break: break-all;
  }

  .q-footer {
    height:50px;
  }

  .text-caption {
    font-size: 13px!important;
      font-weight: 350;
      line-height: 1.5em;
      color: #2F4F4F;
      word-break: normal;
  }

  .text-caption p{
    font-size: 13px;
      font-weight: 350;
      line-height: 1.5em;
      color: #2F4F4F;
      word-break: normal;
  }
  .q-item__label--caption
  {
    color: #000000!important;
  }
  .q-icon{
    margin-right: 10px;
  }

  .q-icon-white{
    margin-right: 10px;
    color: white;
  }

  .q-item__section .q-item__label{
    line-height: 30px!important;
  }

  a {
    text-decoration: none;
    color: #000000;
  }

  .q-tabs__content--align-center {
    justify-content: left!important;
  } 

</style>

<script>
import { ref } from 'vue'
import { useAuthStore } from '@/stores';

export default {


  setup () {
    const authStore = useAuthStore();

    return {
      leftDrawerOpen: ref(false),
      authStore
    }
  }
}

</script>
